import designWithAIAsset from '../../assets/pages/journey/design-with-ai.gif';
import launchAtStore from '../../assets/pages/journey/launch-at-store.png';
import enjoyAutomaticManufacturing from '../../assets/pages/journey/enjoy-automatic-manufacturing.png';

import horizontalDesignWithAIAsset from '../../assets/pages/journey/h_design-with-ai.gif';
import horizontalLaunchAtStore from '../../assets/pages/journey/h_launch-at-store.png';
import horizontalEnjoyAutomaticManufacturing from '../../assets/pages/journey/h_enjoy-automatic-manufacturing.png';

export const cards = [
  {
    title:
      'Create your own online store and POD product designs using AI in minutes ',
    description:
      'Pick your favourite products from t-shirts, hoodies, bags, mugs, and more... Effortlessly apply AI-powered designs or your custom designs to a wide range of products in just a few clicks',
    image: designWithAIAsset,
    horizontalImage: horizontalDesignWithAIAsset,
  },
  {
    title:
      'Launch on Amazon, Shopify, and Etsy all at the same time ',
    description:
      'Pick your favourite products from t-shirts, hoodies, bags, mugs, and more... Effortlessly apply AI-powered designs or your custom designs to a wide range of products in just a few clicks',
    image: launchAtStore,
    horizontalImage: horizontalLaunchAtStore,
  },
  {
    title: 'Enjoy automatic manufacturing, shipping, and customer fulfilment ',
    description:
      'Experience seamless and hands-free operations with automatic manufacturing, shipping, and customer fulfilment. Let Launchify take care of the entire process, so you can focus on growing your business',
    image: enjoyAutomaticManufacturing,
    horizontalImage: horizontalEnjoyAutomaticManufacturing,
  },
];
