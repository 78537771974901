import { colors } from '../../styles/colors';
import { pricingMenu } from './menu';
const Pricing = () => {
  return (
    <div
      className="flex pb-20  flex-col justify-start items-center
    w-full h-full relative overflow-hidden bg-white"
      id="pricing"
    >
      <div
        className="absolute xl:w-[1360px] xl:h-[770px] lg:w-[977px]  xs:h-[1390px] 
      lg:h-[734px] md:w-[720px] md:h-[655px] xs:w-full md:rounded-[40px] xs:rounded-none bg-[#f5f6f7]"
      />
      <div
        id="bg-coloring"
        className="bg-ellipse-pink-coloring z-10 absolute xl:w-[620px] xl:h-[620px] right-[60%] top-[35%]
         lg:w-[750px] lg:h-[750px] 
         md:w-[750px] md:h-[750px]  
         
         xs:w-[950px] xs:h-[950px]   "
      ></div>{' '}
      <div
        id="bg-coloring"
        className="bg-ellipse-pink-coloring z-10 absolute xl:w-[620px] xl:h-[620px] left-[60%] bottom-[35%]
       lg:w-[750px] lg:h-[750px] 
       md:w-[750px] md:h-[750px]  
       
       xs:w-[950px] xs:h-[950px]   "
      ></div>
      <div
        className="flex flex-col justify-start items-center 
       xl:px-[155px] lg:px-[100px] md:px-[24px] top-[110px] py-10 gap-10 z-20"
      >
        <div className="flex flex-col justify-start items-center flex-grow-0 flex-shrink-0 gap-4">
          <div className="flex flex-col justify-start items-center flex-grow-0 flex-shrink-0 relative gap-2">
            <p className="flex-grow-0 flex-shrink-0 xl:text-[48px] lg:text-[40px] md:text-[32px] xs:text-[24px]  font-medium text-left text-[#0c062c]">
              Blueprints for Success
            </p>
          </div>
        </div>
        <div
          className="flex justify-center items-center flex-grow-0 flex-shrink-0 
        xl:w-[1130px] xl:py-[58px] 
        lg:w-[890px] lg:py-[40px]  
        md:w-[676px] md:py-[30px]   gap-6 xs:w-[330px]
        xl:px-20 lg:px-[40px] md:px-[24px]  xs:py-5
        rounded-[40px] bg-[#b349ed]/[0.08] backdrop-blur-2xl"
        >
          <div
            className="flex md:flex-row xs:flex-col justify-start items-start 
          flex-grow-0 flex-shrink-0 relative md:gap-6 xs:gap-8"
          >
            {pricingMenu.map(
              (
                { title, price, period, description, options, bestSeller },
                idx
              ) => (
                <div
                  className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 
                       xl:w-[322px] lg:w-[254px] md:w-[192px] xs:w-[294px] relative gap-10
                        lg:px-8 md:px-6 xs:px-4 pt-8 lg:pb-8 md:pb-6  xs:pb-[24px] rounded-[24px] bg-white "
                  style={{
                    borderWidth: 2,
                    borderColor: bestSeller
                      ? colors.main.secondary[700]
                      : 'white',
                  }}
                  key={idx}
                >
                  <div
                    className="flex flex-col md:justify-start xs:justify-center md:items-start xs:items-center 
                  self-stretch flex-grow-0 flex-shrink-0 relative lg:gap-6 md:gap-4 xs:gap-4"
                  >
                    <p
                      className="flex-grow-0 flex-shrink-0 xl:text-[28px] lg:text-[24px] md:text-[20px] xs:text-[20px] 
                           font-medium md:text-left xs:text-center text-[#1a0d60]"
                    >
                      {title}
                    </p>
                    <div className="flex justify-start items-end flex-grow-0 flex-shrink-0 relative gap-0.5">
                      <p
                        className="flex-grow-0 flex-shrink-0 xl:text-7xl 
                      lg:text-[56px] md:text-[48px] xs:text-[40px] text-left text-[#1a0d60]"
                      >
                        {price}
                      </p>
                      <p
                        className="flex-grow-0 flex-shrink-0 xl:text-base lg:text-[14px] xs:text-[14px]  
                      text-left text-[#1a0d60] pb-3"
                      >
                        /{period}
                      </p>
                      <p
                        className="flex-grow-0 absolute top-1 lg:-left-3 md:-left-2 xs:-left-2  flex-shrink-0 
                      lg:text-[18px] xs:text-[14px]  font-medium text-left text-[#1a0d60]"
                      >
                        $
                      </p>
                    </div>
                    <p
                      className="self-stretch flex-grow-0 flex-shrink-0
                       xl:w-[258px] lg:w-[190px] md:w-[144px]  lg:h-auto md:h-[44px] 
                    xl:text-base lg:text-[14px] xs:text-[14px]  text-left text-[#0c062c]/80"
                    >
                      {description}
                    </p>
                    <div
                      className="flex flex-col justify-start items-start self-stretch 
                    flex-grow-0 flex-shrink-0 gap-4 xl:w-[258px] lg:w-[200px] md:w-[144px] "
                    >
                      {options.map((text, idx) => (
                        <div
                          key={idx}
                          className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-2"
                        >
                          <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 relative gap-2 p-[1.6px] rounded-[12.8px] bg-[#9b17e4]">
                            <svg
                              width={14}
                              height={14}
                              viewBox="0 0 14 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="flex-grow-0 flex-shrink-0 w-[12.8px] h-[12.8px] relative"
                              preserveAspectRatio="xMidYMid meet"
                            >
                              <path
                                d="M10.6 4.2002L5.5 9.4002L3 6.85474"
                                stroke="white"
                                strokeWidth="1.2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                          <p className="flex-grow-0 md:text-left  xl:text-base md:text-[14px] xs:text-[14px]  xs:text-center text-[#0c062c]/80">
                            {text}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="w-full flex justify-start items-start">
                    <div
                      className="flex justify-center items-center border border-[#9b17e4] md:w-auto  xs:w-full
                    flex-grow-0 flex-shrink-0 lg:h-14 md:h-[40px] relative gap-2.5 px-5 py-3 rounded-[40px]"
                      style={{
                        background: !bestSeller
                          ? 'white'
                          : ' linear-gradient(to right, #714bdb 0%, #8553ec 38%, #af48e9 100%)',
                      }}
                    >
                      <p
                        className="flex-grow-0 flex-shrink-0 lg:text-lg md:text-[14px] 
                      font-medium text-center md:normal-case xs:uppercase cursor-pointer"
                        style={{ color: bestSeller ? 'white' : '#9b17e4' }}
                      >
                        Sign up
                      </p>
                    </div>
                  </div>
                </div>
              )
            )}

            <div
              className="flex justify-center items-center flex-grow-0 flex-shrink-0 absolute
               xl:left-[445px] lg:left-[350px] md:left-[265px] lg:top-[-17px] md:top-[-13px]
               xs:top-[386px] xs:left-[110px]
            gap-2.5 md:px-4 xs:px-2 py-1 rounded-xl bg-[#a72dea]"
            >
              <p
                className="flex-grow-0 flex-shrink-0 xl:text-xl lg:text-[16px] xs:text-[12px]
               text-center text-[#f2f2f2]"
              >
                Best Seller
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Pricing;
