export const pricingMenu = [
  {
    title: 'Free Trial',
    price: 0,
    period: 'first week',
    description: 'Limited access for exploration',
    options: ['Lorem ipsum', 'Lorem ipsum dolor sit amet'],
    bestSeller: false,
  },
  {
    title: 'Start-up Plan',
    price: 49,
    period: 'month',
    description: 'Basic features',
    options: ['Lorem ipsum', 'Lorem ipsum dolor sit amet'],
    bestSeller: true,
  },
  {
    title: 'Pro Plan',
    price: 97,
    period: 'month',
    description: 'Advanced features',
    options: ['Lorem ipsum', 'Lorem ipsum dolor sit amet'],
    bestSeller: false,
  },
];
