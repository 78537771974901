import { cards } from './menu';

import { ReactComponent as ArrowLeftAsset } from '../../assets/icons/arrow-left.svg';
import { motion } from 'framer-motion';

import { ReactComponent as ArrowRightAsset } from '../../assets/icons/arrow-right.svg';
import { useEffect, useState } from 'react';
const Journey = () => {
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [isNarrowScreen, setIsNarrowScreen] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsNarrowScreen(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const handleLeftArrowClick = () => {
    setCurrentCardIndex((prev) => (prev > 0 ? prev - 1 : cards.length - 1));
  };

  const handleRightArrowClick = () => {
    setCurrentCardIndex((prev) => (prev < cards.length - 1 ? prev + 1 : 0));
  };
  return (
    <div className="flex xl:pt-[140px] pt-[80px] pb-[0px] flex-col justify-start items-center  w-full h-full relative overflow-hidden bg-white">
      <div className="flex flex-col justify-start items-center relative gap-2">
        <p className="flex-grow-0 flex-shrink-0 xl:text-[48px] lg:text-[40px] md:text-[32px] xs:text-[24px] font-medium text-center text-[#0c062c]">
          <span className="flex-grow-0 flex-shrink-0 font-medium text-center text-[#0c062c]">
            Start Your Journey Stress-Free!
          </span>
          <br />
          <span className="flex-grow-0 flex-shrink-0  font-medium text-center text-[#0c062c]">
            It is as simple as 1, 2, 3!
          </span>
        </p>
      </div>

      <div className="xs:flex md:hidden w-full justify-center items-center mt-[60px]">
        <div className="xs:flex md:hidden w-[335px]  justify-center items-center flex-grow-0 flex-shrink-0 p-1 rounded-lg bg-[#f5f6f7]">
          <div
            className="flex justify-center items-center flex-grow-0 flex-shrink-0 h-8 px-3 py-2 rounded-md bg-[#6839ee]"
            style={{ boxShadow: '0px 2px 2px 0 rgba(100,116,139,0.12)' }}
          >
            <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1.5">
              <p className="flex-grow-0 flex-shrink-0 text-base font-medium text-left text-white">
                POD
              </p>
            </div>
          </div>
          <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 h-8 overflow-hidden px-3 py-2 rounded-lg">
            <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-1.5">
              <p className="flex-grow-0 flex-shrink-0 opacity-[0.38] text-base text-left text-[#0c062c]">
                Dropshipping
              </p>
            </div>
          </div>
          <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 h-8 overflow-hidden px-3 py-2 rounded-lg">
            <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative opacity-[0.38] overflow-hidden gap-1.5">
              <p className="flex-grow-0 flex-shrink-0 text-base text-left text-[#0c062c]">
                Branded Business
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="xl:w-[970px] xl:h-[422px] lg:w-[810px] lg:h-[360px] md:w-[534px] 
  md:h-[285px] xs:w-[325px] xs:h-[430px]
  xs:mt-[95px]
  relative xl:mt-[80px] lg:mt-[80px] md:mt-[56px] justify-center items-center md:ml-[100px]"
      >
        {cards.map(({ title, description, image, horizontalImage }, idx) => {
          const isActive = idx === currentCardIndex;
          const order =
            ((idx - currentCardIndex + cards.length) % cards.length) - 2; // Adjusted for React's zero-based indexing and to center the cards

          const scale = isActive ? 'scale(1)' : 'scale(0.8)';
          const zTranslation = isActive
            ? 'translateZ(0)'
            : 'translateZ(-100px)';
          const value =
            idx - currentCardIndex >= 0
              ? idx - currentCardIndex
              : cards.length + idx - currentCardIndex;
          return (
            <div
              className="absolute h-full  transition-transform duration-500 ease-in-out"
              style={{
                // left: isNarrowScreen ? '' : `${20 * value}px`, // Move each subsequent card further to the right
                bottom: isNarrowScreen ? `${55 * value}px` : '',
                // transform: `scale(${1 - (isNarrowScreen ? 0.2 : 0.2) * value})`, // Scale down each card progressively
                transformOrigin: ` ${isNarrowScreen ? '' : 'right'}  center`, // Ensure scaling is relative to the right center
                zIndex: 20 - value,
                transform: `scale(${
                  1 - (isNarrowScreen ? 0.2 : 0.2) * value
                }) ${zTranslation} ${
                  isNarrowScreen ? `` : `translateX(${order * 7}%)`
                }`,

                transformStyle: 'preserve-3d',
              }}
              key={idx}
            >
              <div
                className="flex md:flex-row xs:flex-col-reverse justify-start items-start overflow-hidden
         md:rounded-[40px] border border-white "
              >
                <div
                  className="flex-grow-0 flex-shrink-0
           xl:h-[422px] xl:w-[322px]
           lg:h-[359px] lg:w-[322px]
           md:h-[285px] md:w-[211px]
           xs:w-[325px] xs:h-[180px]
           relative overflow-hidden xl:rounded-tl-[32px] md:rounded-tl-[28px] 
           xl:rounded-bl-[32px]
           md:rounded-b-[0px]
          xs:rounded-b-[24px] 
           md:rounded-bl-[28px] bg-[#656d7b]/[0.16]"
                >
                  <img
                    className="xl:h-[422px] xl:w-[322px] lg:h-[359px] lg:w-[322px]
           md:h-[285px] md:w-[211px]
           xs:w-[325px] xs:h-[180px]"
                    src={isNarrowScreen ? horizontalImage : image}
                  />
                </div>
                <div
                  className="flex flex-col justify-between items-start flex-grow-0 flex-shrink-0 
          xl:h-[422px] xl:w-[548px] 
           lg:w-[488px] lg:h-[359px] 
           md:w-[322px]  md:h-[285px] 
          relative md:pl-6 md:pr-10 lg:pt-8 md:pt-[24px] lg:pb-10 md:pb-[24px] 
          xs:pl-4 xs:pr-4 xs:py-4
          md:rounded-tr-[32px] md:rounded-br-[32px]
          xs:rounded-tr-[24px] xs:rounded-tl-[24px]
          md:rounded-tl-[0px]
           bg-[#d7cbf9]"
                  style={{
                    background:
                      idx !== currentCardIndex ? '#8060C3' : '#d7cbf9',
                  }}
                >
                  <p
                    className="flex-grow-0 flex-shrink-0 
            xl:w-[395px] lg:w-[395px] md:w-[215px] xs:w-[280px]
            xl:text-[28px] lg:text-[24px] md:text-[18px]  xs:text-[18px]
            font-medium text-left text-[#0c062c] xs:pr-8"
                  >
                    {title}
                  </p>
                  <p
                    className="self-stretch flex-grow-0 flex-shrink-0 md:mt-0 xs:mt-[16px]
            lg:text-[18px] md:text-[14px]  xs:text-[14px]
            xl:w-[484px] lg:w-[424px] md:w-[274px] 
            text-left text-[#0c062c]/80"
                  >
                    {description}
                  </p>
                  <p
                    className="flex-grow-0 flex-shrink-0 
            xl:text-5xl md:text-[40px] xs:text-[32px] font-medium text-left text-white absolute md:right-8 xs:right-4"
                  >
                    0{idx + 1}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className="flex w-full justify-center items-center relative gap-4 mt-[32px] ">
        <button className="" onClick={handleLeftArrowClick}>
          <ArrowLeftAsset />
        </button>
        <button className="" onClick={handleRightArrowClick}>
          <ArrowRightAsset />
        </button>
      </div>
    </div>
  );
};
export default Journey;
