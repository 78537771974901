import { useEffect, useState } from 'react';
import { stockCardsList, stockCardsListSmall } from './menu';
const Stocks = () => {
  const [isNarrowScreen, setIsNarrowScreen] = useState(
    window.innerWidth < 1024
  );

  useEffect(() => {
    const handleResize = () => {
      setIsNarrowScreen(window.innerWidth < 1024);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div
      className="flex flex-col relative bg-white 
    xl:py-[120px] lg:py-[80px] md:pt-[80px] overflow-hidden xs:pt-[60px]"
    >
      <div className="flex flex-col justify-start items-center flex-grow-0 flex-shrink-0 relative gap-4">
        <p
          className="flex-grow-0 flex-shrink-0 xl:w-[750px] lg:w-[750px] md:w-[534px] xs:w-[335px]
        xl:text-5xl lg:text-4xl md:text-3xl xs:text-[24px] font-medium text-center text-[#0c062c]"
        >
          Fulfilment and stock? We got you covered
        </p>
        <p
          className="flex-grow-0 flex-shrink-0 xl:w-[750px] lg:w-[750px] md:w-[534px] xs:w-[80%]
         xl:text-[18px] lg:text-[16px] xs:text-[16px] text-center text-[#0c062c]/80"
        >
          Bid farewell to traditional e-commerce hassles. Our automation handles
          it all - no stock, warehouse, or staff needed. After a purchase, we
          manage everything, letting you sit back and reap the profits
        </p>
      </div>

      <div className="w-full items-center justify-center flex ">
        <div
          className="flex md:flex-row xs:flex-wrap overflow-hidden 
        xl:pt-[80px] lg:pt-[80px] md:pt-[64px] xs:pt-[48px] justify-start items-start flex-grow-0 
      flex-shrink-0 xl:gap-4 lg:gap-3 md:gap-3 xs:gap-6 xl:w-[1138px] lg:w-[830px] md:w-[660px] sm:w-[360px] xs:w-[330px]"
        >
          {(isNarrowScreen ? stockCardsListSmall : stockCardsList).map(
            ({ title, description, Icon }, idx) => (
              <div
                key={idx}
                className="flex xl:w-[210px] lg:w-[152px] md:w-[155px] relative flex-col self-stretch flex-grow md:h-[150px] mt-[8px] sm:h-[135px] "
              >
                {/* Icon component */}
                <div className="relative w-full">
                  <div className="w-full flex justify-center items-center top-[-22px] absolute z-10">
                    <Icon className="xl:w-[52px] xs:w-[45px]" />
                  </div>
                </div>
                <div
                  className="flex flex-col justify-start items-center self-stretch w-full
            flex-grow relative overflow-hidden gap-3 xl:px-6 lg:px-4 md:px-2 lg:pt-12 md:pt-10 xs:pt-8 pb-8 rounded-3xl bg-[#7354f0]"
                  style={{ boxShadow: '0px 4px 24px 0 rgba(155,170,193,0.2)' }}
                >
                  <p
                    className="flex-grow-0 flex-shrink-0
                     xl:w-[166px] md:w-[120px] sm:w-[165px] xs:w-[151px]
              xl:text-20 md:text-18 font-medium text-center text-white"
                  >
                    {title}
                  </p>
                  <p
                    className="flex-grow-0 flex-shrink-0
              xl:text-16 md:text-14  xl:w-[166px] md:w-[120px] sm:w-[165px] xs:w-[151px]
              text-center text-white/80"
                  >
                    {description}
                  </p>
                  <div className="flex-grow-0 flex-shrink-0 w-12 h-[32.39px] absolute left-[83px] top-[84px]" />
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};
export default Stocks;
