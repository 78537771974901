import HashtagCard from '../../components/cards/HashtagCard';
import LogoCard from '../../components/cards/LogoCard';

import { useEffect, useState } from 'react';
import { ReactComponent as SubstractAsset } from '../../assets/main/subtract.svg';
import HappyWomanGIF from '../../assets/pages/landing/happy-woman.gif';
import HappyWomanGIFmd from '../../assets/pages/landing/happy-woman-md.gif';
import HappyWomanGIFsm from '../../assets/pages/landing/happy-woman-sm.gif';
import HappyWomanGIFxs from '../../assets/pages/landing/happy-woman-xs.gif';
import { ReactComponent as IllustrationAsset } from '../../assets/pages/landing/illustration.svg';
import Navbar from '../../components/navbar';
import './index.css';
import {
  businessTypes,
  explanationCards,
  hashtagsRowOne,
  hashtagsRowTwo,
  logotypes,
  progressSteps,
} from './menu';

interface LandingPageInterface {
  isMenuOpen: boolean;
  setIsMenuOpen: (value: boolean) => void;
}
const LandingPage = ({ isMenuOpen, setIsMenuOpen }: LandingPageInterface) => {
  const [width, setWidth] = useState(window.innerWidth);

  const [scrollPercentage, setScrollPercentage] = useState(0);

  const handleScroll = () => {
    const pageElement = document?.getElementById('process');
    if (!pageElement) return;
    // Assuming you have the start and end positions of the current page section
    const sectionStart = pageElement.offsetTop;
    const sectionEnd = sectionStart + pageElement.offsetHeight;

    const scrollPosition = window.scrollY + window.innerHeight;
    let currentScrollPositionWithinSection = scrollPosition - sectionStart;
    let totalSectionHeight = sectionEnd - sectionStart;

    // Ensure we don't exceed 100% or go below 0%
    currentScrollPositionWithinSection = Math.max(
      currentScrollPositionWithinSection,
      0
    );
    let scrollPercentageInSection =
      (currentScrollPositionWithinSection / totalSectionHeight) * 100;
    scrollPercentageInSection = Math.min(scrollPercentageInSection, 100);

    setScrollPercentage(scrollPercentageInSection / 100); // Convert to 0-1 scale
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const getHappyWomanFace = () => {
    if (width < 390) return HappyWomanGIFxs;

    if (width < 768) return HappyWomanGIFsm;
    if (width < 1024) return HappyWomanGIFmd;
    return HappyWomanGIF;
  };
  return (
    <div
      id="landing-page"
      className="flex flex-col bg-[#020315] justify-start items-center  w-full h-full relative overflow-hidden"
    >
      <div className="relative z-20 flex justify-end items-center w-full right-[16px] top-[40px] md:hidden ">
        <div className="absolute mt-[34px]">
          <SubstractAsset />
        </div>
        <button
          id="hamburger-menu-btn"
          className="absolute mr-0 mt-0"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <svg
            className="  "
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width="48"
              height="48"
              rx="24"
              fill="url(#paint0_linear_184_2246)"
            />
            <path
              d="M15 19H33"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
            <path
              d="M15 24H33"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
            <path
              d="M15 29H33"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
            <defs>
              <linearGradient
                id="paint0_linear_184_2246"
                x1="1.78814e-07"
                y1="24"
                x2="48"
                y2="24"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#714BDB" />
                <stop offset="0.38" stopColor="#8553EC" />
                <stop offset="1" stopColor="#AF48E9" />
              </linearGradient>
            </defs>
          </svg>
        </button>
      </div>

      <div id="landing-card-main" className="flex flex-col z-10 w-full">
        <div
          id="landing-card"
          className="bg-white xl:h-[790px] xl:mx-[40px] xl:my-[32px] lg:h-[592px] lg:m-[24px] 
          md:m-[24px] xs:m-[16px] xl:rounded-[40px] lg:rounded-[32px] xs:rounded-[24px] inverted-corner "
        >
          <div id="top-logo" className="relative">
            <div
              className="w-full flex md:justify-center items-center 
            xl:top-[-25px] lg:top-[-15px] md:top-[-15px] xs:top-[10px] xs:justify-start absolute"
            >
              <LogoCard />
            </div>
          </div>
          <div className="flex flex-col h-full  justify-center w-full">
            <div
              className="justify-center lg:flex-row flex-col lg:items-start md:items-center xs:items-center 
               xl:gap-16  h-full flex w-full"
            >
              <div
                className="xl:w-[607px] leading-snug lg:w-[560px] 
              md:w-[560px] xs:w-[340px] xl:pt-[209px] lg:pt-[166px] md:pt-[100px] xs:pt-[90px] "
              >
                <p
                  className=" xl:w-[620px] lg:w-[530px] md:w-[560px] sm:w-[340px] xs:w-full md:text-left xs:text-center
                   xl:text-48 lg:text-40 md:text-32 sm:text-26  xs:text-24 
                   font-outfit font-[400] text-main-background-dark-200 "
                >
                  <span className=" md:text-left xs:text-center text-main-background-dark-200 ">
                    Build an entire e-commerce business in
                  </span>
                  <span className=" font-medium md:text-left xs:text-center text-main-background-dark-200">
                    {' '}
                  </span>
                  <span className="lg:ml-0 md:ml-28 font-medium md:text-left xs:text-center text-main-background-dark-200">
                    10 min{' '}
                  </span>
                  <span className=" md:text-left xs:text-center text-main-background-dark-200">
                    using
                  </span>
                  <span className=" font-medium md:text-left xs:text-center text-main-background-dark-200">
                    {' '}
                    AI
                  </span>
                  <br className="md:hidden" />
                  <span className="  font-medium md:text-left xs:text-center text-main-background-dark-200">
                    {' '}
                    without
                  </span>
                  <span className=" md:text-left xs:text-center text-main-background-dark-200 ">
                    ...{' '}
                  </span>
                  <div className="absolute">
                    <svg
                      viewBox="0 0 187 69"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="relative 
                      xl:bottom-[61px] xl:right-[14px] lg:bottom-[55px] lg:right-[14px]  md:bottom-[47px] md:right-[-304px] 
                      xl:w-[187px] xl:h-[69px] lg:w-[160px] lg:h-[56px] md:w-[184px] md:h-[55px] "
                    >
                      <path
                        d="M176.5 45.0003C233.5 -12.9997 15.9999 -8.49976 2.50001 31.0003C-10.9999 70.5004 142 80.0003 178.5 51.0003"
                        stroke="#A72DEA"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                    </svg>
                  </div>

                  <span className="flex lg:inline-flex flex-row gap-2 xs:justify-center  md:pt-2 xs:pt-2">
                    {hashtagsRowOne.map((hashtag, idx) => (
                      <HashtagCard
                        key={`${hashtag.text}-${idx}`}
                        {...hashtag}
                      />
                    ))}
                  </span>
                </p>
                <div
                  className="flex md:flex-row xs:flex-wrap gap-2 mt-2 
                md:w-max md:justify-start  xs:w-[339px] xs:justify-center"
                >
                  {hashtagsRowTwo.map((hashtag, idx) => (
                    <HashtagCard key={`${hashtag.text}-${idx}`} {...hashtag} />
                  ))}
                </div>
                <div className="lg:flex hidden justify-start items-start gap-6 xl:mt-16 lg:mt-[48px] md:mt-[32px]">
                  {businessTypes.map(({ Icon, text }, idx) => (
                    <div
                      key={idx}
                      className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-2 "
                    >
                      <Icon className="xl:w-[25px] h-auto lg:w-[20px] md:w-[13px] sm:w-[11px] xs:w-[11px] flex-grow-0 flex-shrink-0 w-6 h-6 relative" />

                      <p
                        className="font-outfit flex-grow-0 flex-shrink-0 xl:text-24 lg:text-18 md:text-18
                       text-center  text-main-background-dark-200/80"
                      >
                        {text}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
              <div className=" flex flex-col md:pb-0 xs:pb-4 justify-center items-center  xl:pt-[80px] lg:pt-[90px] md:pt-[0px] xs:pt-[10px]">
                <img
                  className="xl:w-[550px] lg:w-[350px] md:w-[400px] md:ml-[50px] md:h-auto xs:h-[300px] xs:ml-[25px] xs:w-[300px] md:top-0 "
                  src={getHappyWomanFace()}
                />
                {/* <HappyWomanAsset className="xl:w-[550px] lg:w-[350px] md:w-[400px] md:ml-[50px] md:h-auto xs:h-[300px] xs:ml-[25px] xs:w-[300px] md:top-0 " /> */}
                <div
                  className=" md:justify-start md:items-start 
                xs:justify-center xs:items-start  md:gap-6 xs:gap-4 md:mb-16 xs:mb-20 
                flex md:flex-row xs:flex-wrap lg:hidden md:w-auto xs:w-[300px]"
                >
                  {businessTypes.map(({ Icon, text }, idx) => (
                    <div
                      key={idx}
                      className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-2"
                    >
                      <Icon />

                      <p
                        className="font-outfit flex-grow-0 flex-shrink-0 xl:text-24 lg:text-18 md:text-16 xs:text-14
                       text-center  text-main-background-dark-200/80"
                      >
                        {text}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div id="bottom-navbar" className="relative">
            <div className="w-full flex justify-center items-center md:top-[-36px] xs:top-[-70px]  absolute">
              <Navbar />
              <div
                className="xs:flex justify-center items-end w-[95%] h-14 relative gap-2.5 px-7 pb-4 rounded-[40px] md:hidden"
                style={{
                  background:
                    'linear-gradient(to right, #714bdb 0%, #8553ec 38%, #af48e9 100%)',
                }}
              >
                <p className="flex-grow-0 flex-shrink-0 text-base text-center uppercase text-white">
                  Start Free Trial
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col xl:mt-[120px] lg:mt-[96px] md:mt-[80px] xs:mt-[60px] gap-8 z-10 w-full jutify-center items-center">
        <p className="flex-grow-0 md:w-[70%] xs:w-[325px] flex-shrink-0 xl:text-28 lg:text-20 md:text-20 xs:text-16 font-outfit text-center text-[#8c72f3]">
          Seamlessly integrate your e-commerce and social platforms in just
          seconds
        </p>

        <div
          className="flex  overflow-hidden justify-center items-center
         flex-grow-0 flex-shrink-0 xl:w-[80%] lg:w-[80%] md:w-[80%] xs:w-[90%] relative "
        >
          <div
            className={`flex ${
              width > 768
                ? 'marquee'
                : 'flex-wrap md:w-[450px] xs:w-[342px] gap-8 justify-center '
            } xl:gap-[60px] lg:gap-[40px] md:gap-[24px] xs:gap-[24px]`}
          >
            {(width > 768 ? [...logotypes, ...logotypes] : [...logotypes]).map(
              (logoType, idx) => (
                <div
                  className="flex flex-col justify-center
                 items-center xl:h-[42px] 
                 lg:h-[42px] md:h-[36px] xs:h-[24px] relative gap-[20px] "
                  key={idx}
                >
                  <logoType.Icon className="xl:h-[42px] lg:h-[42px] md:h-[36px] xs:h-[24px] w-max" />
                </div>
              )
            )}
          </div>
        </div>
      </div>
      <div
        className="flex w-full flex-col items-center justify-center relative"
        id="benefits"
      >
        <div
          className="flex relative 
          xl:mt-[186px] lg:mt-[160px] md:mt-[120px] sm:mt-[116px] xs:mt-[96px] justify-between md:items-center xs:items-start 
          xl:w-[1054px] lg:w-[810px] md:w-[655px] xs:w-[90%] z-10 
        
        xl:px-[60px] lg:px-[48px] md:px-[32px] xs:px-[24px]
        xl:pt-12 lg:pt-8 md:pt-6 xl:pb-[60px] lg:pb-[40px] md:pb-[32px] sm:pt-[24px]
         xs:pt-[16px] sm:pb-[32px] xs:pb-[24px]
        xl:rounded-[40px] lg:rounded-[32px] xs:rounded-[24px] z-1  bg-white"
        >
          <div
            className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0
           xl:w-[720px] lg:w-[590px] md:w-[490px] xs:w-[80%] relative gap-6"
          >
            <p
              className="flex-grow-0 flex-shrink-0
             xl:w-[720px] lg:w-[590px] md:w-[490px] xs:w-full lg:leading-[45px] xl:leading-[55px]
              xl:text-48 lg:text-40 md:text-32 xs:text-24 font-outfit text-left text-main-background-dark-200"
            >
              <span
                className="flex-grow-0 font-medium flex-shrink-0
               xl:w-[720px] lg:w-[590px] md:w-[490px] xs:w-full text-left text-main-background-dark-200"
              >
                Perfect for beginners. Built by
              </span>
              <br className="md:block xs:hidden" />
              <span className="md:hidden"> </span>
              <span
                className="flex-grow-0 font-medium flex-shrink-0
               xl:w-[720px] lg:w-[590px] md:w-[490px] xs:w-full text-left text-main-background-dark-200"
              >
                e-commerce sellers for everyone{' '}
              </span>
            </p>
            <p
              className="self-stretch flex-grow-0 flex-shrink-0
             xl:w-[720px] lg:w-[590px] md:w-[490px] xs:w-[120%] 
             xl:text-18 lg:text-16 xs:text-16 text-left text-main-background-dark-200/80"
            >
              Over 10 years experience all built within Launchify. No more time
              wasting, second guessing, no manual setup. In 10 minutes you will
              have your own business.
            </p>
            <div className="absolute">
              <svg
                viewBox="0 0 237 90"
                className="xl:w-[250px] xl:h-[95px] xl:left-[232px] xl:top-[-12px] 
                
                lg:w-[210px] lg:h-[95px] lg:left-[192px] lg:top-[-19px] 
                md:w-[163px] md:h-[90px] md:left-[155px] md:top-[-15px] 
                sm:w-[120px] sm:h-[90px] sm:left-[95%] sm:top-[-25px] 
                relative"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M222.5 45C263.499 2.50002 8.20618 -21.0239 2.13718 35.8927C-3.93181 92.8094 237 76 233 42.5"
                  stroke="#A72DEA"
                  stroke-width="3"
                  stroke-linecap="round"
                />
              </svg>
            </div>
          </div>
          <IllustrationAsset className="md:w-auto md:h-auto xs:h-[70px] " />
        </div>
        <div
          id="background-card"
          className="flex flex-col w-full z-[5] absolute px-[40px] md:px-[24px] xl:top-[30%] lg:top-[35%]"
        >
          <div className="xl:h-[454px] lg:h-[336px] md:h-[336px] w-full xl:rounded-[40px] lg:rounded-[32px] xs:rounded-[24px] bg-[#6839ee]/[0.24]"></div>
        </div>

        <div
          id="explanation-cards"
          className="flex md:flex-row xs:flex-col justify-start items-start 
          xl:w-[900px] lg:w-[800px] md:w-[655px] sm:w-[355px] xs:w-[328px] md:gap-6 xs:gap-12
          xl:mt-[120px] lg:mt-[96px] md:mt-[80px] xs:mt-[96px] z-10"
        >
          {explanationCards.map(({ Icon, text, description }, idx) => (
            <div
              key={idx}
              className="flex flex-col self-stretch flex-grow relative md:h-auto xs:h-[208px]"
            >
              {/* Icon component */}
              <div className="relative w-full">
                <div
                  className="w-full flex md:justify-center items-center xs:justify-end
                 top-[-30px] md:right-0 xs:right-6 absolute"
                >
                  <Icon />
                </div>
              </div>
              <div
                className=" flex flex-col justify-start items-start self-stretch flex-grow  
    overflow-hidden gap-3 xl:px-5 lg:px-4 md:px-4 md:pb-6 xs:pb-6  xl:rounded-[40px]
     lg:rounded-[32px] xs:rounded-[24px] xs:px-6 bg-white h-full font-outfit"
              >
                <p
                  className="self-stretch flex-grow-0 flex-shrink-0 
                xl:w-[250px] lg:w-[220px] md:w-[175px]  font-medium xl:text-20 md:text-18 xs:text-18
                 md:text-center xs:text-left text-main-background-dark-200 md:mt-12 xs:mt-8"
                >
                  {text}
                </p>
                <p
                  className="self-stretch flex-grow-0 flex-shrink-0
                 xl:w-[250px] lg:w-[220px] md:w-[175px] 
                 xl:text-18 md:text-16 xs:text-16  md:text-center 
                 xs:text-left text-main-background-dark-200/60"
                >
                  {description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div
        className="flex flex-col z-10 relative justify-center items-center 
        xl:mt-[186px] lg:mt-[160px] xs:mt-[80px] md:mt-[120px] 
      lg:mb-[96px] xl:mb-[120px]  md:mb-[80px] xs:mb-[64px]"
        id="process"
      >
        <div
          id="process-title"
          className="flex flex-col md:justify-start xs:justify-center items-center md:w-max xs:w-full top-0 gap-4"
        >
          <p
            className="flex-grow-0 md:w-auto sm:w-[60%] xs:w-[60%] 
          flex-shrink-0 xl:text-48 lg:text-40 md:text-32 xs:text-24 
          font-medium md:text-left xs:text-center text-white"
          >
            Really in 10 mins? Yes! Fast and easy
          </p>
          <p
            className="flex-grow-0 flex-shrink-0 lg:w-[750px] md:w-[535px] xs:w-[70%] 
          xl:text-18 lg:text-16 md:text-16 xs:text-16 text-center text-white/80"
          >
            Unleash AI Superpowers! Build your entire website, pick your
            product, design your product, publish it to Shopfiy, Amazon &amp;
            Etsy all at the same time with Launchify
          </p>
        </div>
        <div
          className="flex  w-full md:flex-col  relative xs:justify-center "
          id="progress-stick"
        >
          <div className="relative flex justify-center items-center md:left-0 xs:left-[30px]">
            <div
              id="progress-stick-loaded"
              className="xl:w-[800px] lg:w-[750px] md:w-[550px] xs:w-1.5 md:h-1.5 
              xs:h-[500px] absolute md:left-[100px]
              bg-[#a72dea] md:top-[95px] xs:top-[90px] rounded-lg transition-transform duration-500 ease-in-out"
              style={{
                width: (function (): any {
                  if (window.innerWidth < 768) return '6px';
                  const baseWidth =
                    window.innerWidth >= 1280
                      ? 820
                      : window.innerWidth >= 1024
                      ? 850
                      : window.innerWidth >= 768
                      ? 550
                      : window.innerWidth * 0.2; // Assuming xs:w-1/5 as 20% of the viewport width

                  return `${baseWidth * scrollPercentage}px`;
                })(),
                height: (function (): string {
                  if (window.innerWidth > 768) return '6px';
                  const baseWidth = 500;
                  return `${baseWidth * scrollPercentage}px`;
                })(),
              }}
            />

            <div
              id="progress-stick-in-progress"
              className="xl:w-[800px] lg:w-[750px] md:w-[550px] xs:w-1.5 md:h-1.5 xs:h-[500px] absolute
              bg-[#B349ED]/[0.16] md:top-[95px] xs:top-[90px]"
            />
          </div>
          <div className="flex md:flex-row xl:gap-[32px] md:gap-[32px] xs:gap-[40px] xs:flex-col justify-between items-start  mt-16 z-[5]">
            {progressSteps.map(
              ({ Icon, text, description, subDescription }, idx) => (
                <div
                  key={idx}
                  className="flex md:gap-0 xs:gap-4 md:flex-col flex-row md:justify-center xs:jutify-start
                 md:h-auto xs:h-[120px]  md:self-stretch flex-grow md:items-center xs:items-start "
                >
                  <Icon />
                  <div
                    className="flex flex-col md:pt-[24px] justify-start md:items-center xs:items-start flex-grow xs:mt-3 md:mt-0 
                  lg:w-60 md:w-[156px] xs:w-[270px] relative md:gap-3 xs:gap-2"
                  >
                    <p className="flex-grow-0 flex-shrink-0 xl:text-28 lg:text-20 md:text-20 font-medium text-center text-white">
                      {text}
                    </p>
                    <p className="flex-grow-0 flex-shrink-0 xl:text-18 lg:text-14 md:text-14 md:text-center xs:items-start text-[#d9d9d9]/60">
                      {description}
                    </p>
                    <p className=" md:hidden justify-end flex font-medium text-center text-[#e2a4f8]">
                      {subDescription}
                    </p>
                  </div>
                  <p
                    className=" md:flex xs:hidden xl:text-18 lg:text-14 md:text-14 mt-2
                   justify-end flex font-medium text-center text-[#e2a4f8]"
                  >
                    {subDescription}
                  </p>
                </div>
              )
            )}
          </div>
        </div>
      </div>
      {/* Top Right */}
      <div
        id="bg-coloring"
        className="bg-ellipse-coloring absolute w-[950px] h-[950px] md:right-[-30%] md:top-[-10%] xs:right-[30%] top-[-10%]"
      ></div>

      {/* Left Middle  */}
      <div
        id="bg-coloring"
        className="bg-ellipse-coloring absolute w-[950px] h-[950px] left-[-30%] top-[20%]  "
      ></div>

      {/* Bottom Right */}
      <div
        id="bg-coloring"
        className="bg-ellipse-coloring absolute w-[950px] h-[950px] right-[-30%] top-[40%] "
      ></div>

      {/* Middle Bottom */}
      <div
        id="bg-coloring"
        className="bg-ellipse-coloring-text absolute xl:w-[950px] xl:h-[950px]
         lg:w-[750px] lg:h-[750px] 
         md:w-[750px] md:h-[750px]  
         md:top-[65%] xs:top-[65%] 
         xs:w-[950px] xs:h-[950px]   "
      ></div>
    </div>
  );
};

export default LandingPage;
