import { businessSelect } from './menu';

import { ReactComponent as ArrowLeftAsset } from '../../assets/icons/arrow-left.svg';

import { ReactComponent as ArrowRightAsset } from '../../assets/icons/arrow-right.svg';
import { useRef } from 'react';
const BusinessModels = () => {
  // Create a ref for the scrolling container
  const scrollContainerRef: any = useRef(null);

  // Function to scroll left
  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -200, behavior: 'smooth' });
    }
  };

  // Function to scroll right
  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 200, behavior: 'smooth' });
    }
  };
  return (
    <div
      className="flex  flex-col justify-start items-center  w-full h-full relative 
    overflow-hidden bg-white"
      id="products"
    >
      <div className="flex flex-col justify-start items-center relative gap-4">
        <p
          className="flex-grow-0 flex-shrink-0 md:w-[750px] xs:w-[80%] 
        xl:text-48 lg:text-40 md:text-32 xs:text-24  
        font-medium text-center text-[#0c062c]
        
        
        "
        >
          Select your e-commerce business model
        </p>
        <p
          className="flex-grow-0 flex-shrink-0  xs:text-center text-[#0c062c]/80
        xl:text-18 lg:text-16 md:text-18 xs:text-16 sm:w-[80%] xs:w-[85%] 

        "
        >
          We offer 3 e-commerce business models — all using AI
        </p>
      </div>
      <div
        className="xl:mt-[80px] lg:mt-[64px] md:mt-[40px] xs:mt-[40px] xl:text-18 lg:text-18 md:text-16 xs:text-14 
      flex justify-start items-center md:pl-2 md:pr-2 md:py-2 xs:py-[4px] xs:pl-[4px] xs:pr-[16px] md:rounded-2xl xs:rounded-[6px] bg-[#eff1f4] sm:flex xs:hidden"
      >
        <div
          className="flex justify-center items-center flex-grow-0 flex-shrink-0
         xl:h-14 lg:h-[48px]  md:h-[40px] xs:h-[32px] 
         relative gap-2.5 md:px-8 xs:px-4  md:rounded-xl xs:rounded-[4px] bg-[#6839ee]"
        >
          <p className="flex-grow-0 md:flex xs:hidden flex-shrink-0 font-medium text-center text-white">
            Print on Demand
          </p>
          <p className="flex-grow-0 md:hidden flex-shrink-0 font-medium text-center text-white">
            POD
          </p>
        </div>
        <div
          className="flex justify-center items-center flex-grow-0 flex-shrink-0
         xl:w-[200px] lg:w-[184px] md:w-[153px] xs:w-[120px]
         relative gap-2.5"
        >
          <p className="flex-grow-0 flex-shrink-0 opacity-[0.38]  text-center text-[#0c062c]">
            Dropshipping
          </p>
        </div>
        <div
          className="flex justify-center items-center flex-grow-0 flex-shrink-0 
        
        xl:w-[200px] lg:w-[184px] md:w-[153px] xs:w-[125px] relative gap-2.5"
        >
          <p className="flex-grow-0 flex-shrink-0 opacity-[0.38]  text-center text-[#0c062c]">
            Branded Business
          </p>
        </div>
      </div>
      <div
        className=" md:overflow-x-scroll xs:overflow-x-auto flex justify-start items-start 
      relative gap-5 xl:mt-[48px] lg:mt-[40px] md:mt-[40px] xs:mt-[40px] w-full xl:px-[140px] lg:px-[120px] md:px-[60px] xs:px-[30px] "
        ref={scrollContainerRef}
      >
        {businessSelect.map(({ text, description, image }, idx) => (
          <div
            key={idx}
            className="flex-grow-0 flex-shrink-0 flex-col
            md:w-[380px] md:h-[460px] xs:w-[300px] xs:h-[460px]
          relative xs:px-[16px] xs:py-[20px] lg:px-[20px] lg:py-[22px]  bg-[#eaeaee] rounded-[16px] mb-2"
          >
            <img src={image} alt="Description" className="rounded-[13px]" />
            <div className="flex  flex-col justify-start items-start  py-4 mb-2  gap-3">
              <p
                className="self-stretch flex-grow-0 flex-shrink-0  xs:text-20 font-medium text-left
               text-[#0c062c]"
              >
                {text}
              </p>
              <p className="self-stretch flex-grow-0 flex-shrink-0   xs:text-16 text-left text-[#0c062c]/80">
                {description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default BusinessModels;
